import { Layout, SubHeader } from 'components/global'
import LegalBody from 'components/shared/legalBody'

import React from 'react'

const TermsConditions = () => (
  <Layout $noAnimation>
    <SubHeader title="Terms &amp; Conditions" />
    <LegalBody>
      <strong>Introduction</strong>
      <p>
        Welcome to the arnoldclarkcup.com website (&ldquo;the Website&rdquo;).
        These are the terms and conditions (&ldquo;Terms of Use&rdquo;) which
        govern your use of the Website. These Terms of Use apply regardless of
        the&nbsp;user device you are using (including desktop, laptop or mobile
        web browser, digital television, mobile phones, automobile-based
        personal computers, handheld digital devices, and any other mobile
        device or technology whether now known or developed in the future).
      </p>
      <p>
        You will be deemed to have agreed to be bound by these Terms of Use when
        you use, access or browse the Website. If you do not agree to or do not
        want to be bound by these Terms of Use, you are not authorised to use
        this Website and should immediately exit the Website.
      </p>
      <p>
        The Website is operated by or on behalf of Pitch International LLP
        ("Pitch", "we" or "our"). We are a limited liability partnership
        registered in England and Wales with the company registration number
        OC309250. Our registered office address is 17 Brewhouse Lane, Putney
        Wharf, London SW15 2JX, United Kingdom and our VAT number is 846386784.
      </p>
      <p>
        For the purposes of these Terms of Use: "Content" includes all or part
        of any text, graphics, layout, logos, images, audio material, films or
        other moving images, product details and/or software published or
        otherwise available on the Website from time to time (including,
        anything made available for download); &ldquo;including&rdquo; and its
        derivations mean &ldquo;including, without limitation&rdquo;; "material"
        includes all or part of any text, graphics, layout, logos, images, audio
        material, films or other moving images; and "Trade Marks" means the
        trade marks, logos and service marks (whether or not registered)
        displayed on the Website.
      </p>

      <strong>Changes to these Terms of Use</strong>

      <p>
        We may change these Terms of Use from time to time. Please check these
        Terms of Use regularly as any changes are effective immediately upon
        posting to the Website. Your continued use of the Website after posting
        will be deemed acceptance of the changes.
      </p>

      <strong>Intellectual Property</strong>

      <p>
        We are the owner or the licensee of all intellectual property rights in
        the Website, the Content and the Trade Marks. Subject to these Terms of
        Use, we grant you a limited, temporary, revocable, and non-exclusive
        licence to access, browse and use the Website (including the Content and
        Trade Marks) in accordance with the restrictions set out below.
      </p>

      <strong>Access and use of the Website</strong>

      <p>You may not:</p>
      <ul>
        <li>
          download or print any Content or extracts in a systematic or regular
          manner or otherwise so as to create a database in electronic or paper
          form comprising all or part of the Content from the Website; nor
        </li>
        <li>
          reproduce, republish, modify, archive, distribute, store, archive or
          commercially exploit the Content without our prior written consent;
          nor
        </li>
        <li>modify or adapt or create derivative works of the Content; nor</li>
        <li>
          copy or extract data from the Website by means of automatic devices or
          by any manual process used to systematically extract or copy web
          content.
        </li>
      </ul>
      <p>
        At all times when you are accessing, browsing or using the Website, you
        must do so lawfully and legitimately and without infringing or
        restricting anyone else&rsquo;s rights or use and enjoyment of the
        Website. In particular, you must not use the Website in a manner or
        upload any material or link to any material which is false, fraudulent,
        inaccurate or misleading, obscene, offensive, defamatory, abusive or in
        breach of any third party rights (including intellectual property
        right).
      </p>
      <p>
        You are responsible for making all arrangements necessary for you to
        have access to the Website. You are also responsible for ensuring that
        all persons who access the Website through your internet connection are
        aware of these Terms of Use and other applicable terms and conditions,
        and that they comply with them.
      </p>
      <p>
        At any time without notice and in our absolute discretion, we may
        permanently or temporarily terminate, suspend or deny your access to the
        Website. At any time without notice and in our absolute discretion, we
        may remove or edit any Content on the Website. To the fullest extent
        permitted by law, in both cases none of Pitch, its directors, employees
        or other representatives have any liability to you whatsoever for any
        loss or damage arising from such removal or editing or any restriction
        or hindrance to your use of the Website.
      </p>
      <p>
        You must not use any part of the Content or the Website for commercial
        purposes without obtaining a licence to do so from us or our licensors.
      </p>
      <strong>Privacy</strong>
      <p>
        Your privacy is important to us. Please see our Privacy Policy for
        details about what information we collect and how we will use and
        protect it.
      </p>
      <strong>Username</strong>
      <strong> and Password</strong>
      <p>
        You may be required to register as a user of the Website to be able to
        access certain areas of the Website. In such a case, you will need to
        provide a username and password when doing so. You must ensure that you
        keep your username and password secret and that you do not disclose them
        to anyone, as you will be responsible for all activities which occur
        under your username and password. It is your responsibility to notify us
        immediately of any unauthorised use of your username or password or any
        other breach of security as soon as you become aware of it.
      </p>
      <strong>Links to External Websites</strong>
      <p>
        The Website may include links to external websites. These links are
        provided to help you find additional information quickly and easily.
        When you click on a link, you may leave this website and content
        controlled by us, and will be linked to the website of an external third
        party (&ldquo;Linked Website&rdquo;). We do not have any influence on
        the information on the Linked Website and are not liable for the content
        or use of the Linked Website and accept no responsibility for them or
        for any loss or damage that may arise from your use of them.
      </p>
      <strong>Exclusions and limitations of liability</strong>
      <p>
        All information and/or data on the Website is provided on an "as is"
        basis. Save to the extent required by law, no representations,
        warranties or terms of any kind are made (or shall be implied by statute
        or otherwise) in respect of the Website or the Content, including,
        without limitation, warranties of satisfactory quality, conformity to
        contract, accuracy, adequacy, conformity to description or fitness for
        any particular purpose.
      </p>
      <p>
        Neither Pitch nor any of its data providers or affiliates gives any
        warranty or guarantee relating to availability of the Website or that
        the Website and/or our operation of it, the Content or the server that
        makes the Website available are error or virus free or free of other
        harmful components or that your use of the Website and/or the Content
        will be uninterrupted.
      </p>
      <p>
        You agree that Pitch, its directors, employees, agents or other
        representatives, data providers or affiliates will not be responsible or
        liable (whether in contract, tort or otherwise), under any circumstances
        for any amount or kind of loss or damage (including without limitation,
        any direct, indirect, punitive or consequential loss or damages, or any
        anticipated loss of profit, loss of profit, loss of opportunity, loss of
        data, costs and fines and/or any special or incidental damages of any
        kind) that may result to you or a third party arising from or connected
        in any way to:
      </p>
      <ul>
        <li>interruption of business; or</li>
        <li>
          access or other delays, terminations, suspensions, denials or access
          interruptions to the Website; or
        </li>
        <li>
          data non-delivery, data misdelivery, data corruption, destruction of
          data or other modification of data; or
        </li>
        <li>third party website links on the Website; or</li>
        <li>reliance on the information contained on the Website; or</li>
        <li>
          computer viruses, system failures or malfunctions which may occur in
          connection with your use of the Website; or
        </li>
        <li>
          any inaccuracies, omissions or misleading, false or deceptive
          statement in the Content; or
        </li>
        <li>events beyond our reasonable control.</li>
      </ul>
      <p>
        Notwithstanding any provision of these Terms of Use, Pitch does not
        exclude or limit its liability for:
      </p>
      <ul>
        <li>
          death or personal injury caused by its negligence or that of any of
          its officers, employees or agents; or
        </li>
        <li>fraudulent misrepresentation; or</li>
        <li>
          any liability which it is not lawful to exclude either now or in the
          future.
        </li>
      </ul>
      <p>
        <strong>Indemnity</strong>
      </p>
      <p>
        You will indemnify and will keep indemnified Pitch and its data
        providers and affiliates on demand against all claims, costs,
        proceedings, demands, losses, damages, expenses (including legal
        expenses) or liability whatsoever arising directly or indirectly as a
        result of:
      </p>
      <ul>
        <li>any breach of these Terms of Use by you; or</li>
        <li>your fault, negligence or breach of statutory duty.</li>
      </ul>
      <p>
        <strong>General</strong>
      </p>
      <p>
        Any contractual or legal relationship between you and Pitch will be
        concluded in English.
      </p>
      <p>
        All notices shall be given by e-mail to us at{' '}
        <a href="mailto:info@pitchinternational.com">
          info@pitchinternational.com
        </a>
        .
      </p>
      <p>
        These Terms of Use (together with any variations to them) form the
        entire agreement between the parties concerning your access to, browsing
        and/or use of the Website and supersede all prior agreements,
        arrangements, understandings and representations made between us
        (whether written or oral) concerning the Website.
      </p>
      <p>
        The licence granted in section 3 above is personal to you and may not be
        assigned, transferred or sub-licensed (in whole or in part) without our
        prior written consent.
      </p>
      <p>
        Nothing in these Terms of Use is intended to or shall operate to create
        a partnership or joint venture of any kind between us or to authorise
        either of us to act as agent for the other, and neither of us shall have
        authority to act in the name or on behalf of or otherwise to bind the
        other in any way (including but not limited to the making of any
        representation or warranty, the assumption of any obligation or
        liability and the exercise of any right or power).
      </p>
      <p>
        If the whole or any part of any provision of these Terms of Use is or
        becomes invalid, void or unenforceable for any reason the same shall to
        the extent required be severed from these Terms of Use and rendered
        ineffective so far as is possible without modifying the remaining
        provisions of these Terms of Use and shall in no way affect the validity
        or enforceability of any other provisions.
      </p>
      <p>
        No waiver by Pitch of any breach of these Terms of Use shall constitute
        a waiver of any other prior or subsequent breach and Pitch shall not be
        affected by any delay, failure or omission to enforce or express
        forbearance granted in respect of any of your obligations.
      </p>
      <p>
        The rights and remedies of Pitch under these Terms of Use are
        independent, cumulative and without prejudice to its rights under the
        law.
      </p>
      <p>
        These Terms of Use are not intended to create and shall not create any
        rights, entitlements, claims or benefits enforceable by any third party
        by virtue of the Contracts (Rights of Third Parties) Act 1999.
      </p>
      <p>
        These Terms of Use and/or your use of the Website shall be governed by
        and construed in accordance with English law and the English Courts
        shall have exclusive jurisdiction over any dispute which may arise.
      </p>
    </LegalBody>
  </Layout>
)

export default TermsConditions
